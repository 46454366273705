export const links = [
  {
    title: 'Keed',
    label1: { label: 'Home', link: '#home' },
    label2: { label: 'Funcionalidades', link: '#features' },
    label3: { label: 'Novidades', link: '#news' },
    label4: { label: 'Faq', link: '#faq' }
  },
  {
    title: 'Social',
    label1: { label: 'Instagram' },
    label2: { label: 'Facebook' },
    label3: { label: '' },
    label4: { label: '' }
  }
]

export const links1 = [
  {
    title: 'Políticas e Termos',
    label1: { label: 'Termos de Uso', link: '/terms-of-use.pdf' },
    label2: {
      label: 'Termos de uso Pay After Delivery',
      link: '/terms-of-use-pad.pdf'
    },
    label3: {
      label: 'Políticas de Privacidade',
      link: '/privacy-policies.pdf'
    },
    label4: {
      label: 'Termos de Compra KeedPay',
      link: '/keedpay-purchase-terms.pdf'
    }
  }
]

export const supportArray = [
  {
    title: 'Suporte',
    label1: { label: 'WhatsApp', link: 'https://wa.me//5537999374702' },
    label2: {
      label: 'E-mail',
      link: 'mailto:suporte@keedpay.com.br'
    }
  }
]
